
export const enStrings = {
    ponctuationColon: ":",
    color:"Color",
    stroke:"Stroke",
    speed: "Speed",
    logOut: "Log Out",
    accessDenied: "Unauthorized",
    firstConnection: "First connection",
    noPermissions: "Your account does not have the required permissions or is unknown to the application.",
    lastDocs: "Recent files",
    displayDocsAllAgencies: "View documents from my exploitation units",
    fileExtension: "File extension",
    fileName: "File name",
    agency: "Exploitation unit",
    territory: "Territory",
    company: "Legal company",
    productionSite: "Production unit",
    physicalSite: "Physical site",
    contract: "Contract",
    author: "Author",
    lastUpdate: "Last update",
    delete: "Delete",
    showDetail: "Display",
    alert: "Alert",
    yes: "Yes",
    no: "No",
    absent: "Absent",
    present: "Present",
    dragAndDrop: "Drag and drop a file or click to browse",
    btnEditAddressPerimeterEcontractJobSite: "Modify contract/job addresses and contours",
    titleEditAddressPerimeterEcontractJobSite: "Modify contract/job addresses and contours",
    save: "Save",
    cancel: "Cancel",
    state: "State",
    loadingOk: "Loaded successfully",
    loadingKo: "Failed to load",
    ignoredDoc: "Ignored file",
    patrimony: "Power BI",
    dropZone: "Upload",
    map: "Map",
    formTitle: "Complete the project information",
    exit: "Exit",
    ignoreSheet: "Skip file",
    createNewToggle: "Create a new contract",
    structureType: "Entity type",
    agency_mnd: "Exploitation unit",
    label: "Label",
    id: "Id",
    address: "Address",
    email: "Email",
    phoneNumber: "Phone number",
    webLink: "Web link",
    labelContract_mnd: "Contract label",
    labelCodeContract_mnd: "Contract Code/Label",
    TooltipInitialLocation: "Initial location",
    labelCustomContract_mnd: "Contract label (at least 3 characters)",
    documentTitle_mnd: "Document title",
    searchBar: "Enter 3 characters to start the search",
    searchBarCustomContract: "Enter at least 3 characters",
    searchBarNoResult: "No result, please enter at least 3 characters",
    searchBarNoResultHasMinLength: "No result",
    contractCode: "Contract code",
    contractClient: "Contract client",
    contractState: "Contract state",
    documentInformation: "Complete the document information",
    documentCreationTime: "File creation date",
    documentState: "Phase",
    projection: "Cartographic projection",
    category: "Data category",
    dataType: "Data type",
    intellectualProperty: "Intellectual property",
    localization: "Localization",
    locationSearchBar: "Search for an address",
    prevDoc: "Previous document",
    nxtDoc: "Next Document",
    loaded: "loaded",
    next: "Next",
    summTitle: "Summary of loads",
    finalize: "Finalize",
    noDoc: "No Document",
    loadedDocs: "Loaded Documents",
    deletionDialogTitle: "Delete a document",
    deletionDialogContent: "Confirm deletion of file",
    deletionDialogConfirmation: "Yes",
    deletionDialogCancelation: "No, return",
    restrictionshowDetailMessage: "Access to this document is restricted to the exploitation unit on which it was filed. For more information please contact:",
    preventShareToOtherStructures: "I don't want to share this document with other exploitation units",
    businessCheck: "Business",
    jobCheck: "Job",
    jobSiteIdentifier: "IdMdM",
    jobTooltip: "Allows associate document with a job",
    contractTooltip: "Allows associate document with a contract",
    jobTitle: "Select job",
    edit: "Edit",
    confirmEdition: "Update",
    rename:"Rename",
    noRecords: "No records available.",
    tags: "Tags",
    addTag: "Add tag",
    keepTagForFutureFiles: "Keep tags for future files",
    forgeViewer: "Viewer AutoDesk",
    fileViewer: "File viewer",
    uploadSearchLabel: "Search..",
    uploadToggleButtonMyDocuments: "My documents",
    uploadToggleButtonMyAgencies: "My structures",
    uploadPopUpButtonBack: "Back",
    unavailableFileErrorMessage: "File not available. Please contact your administrator.",
    fileDownloadAccessErrorMessage: "This document is private, ask pierre.marechal@colas.com or benjamin.lecot@colas.com to get access.",
    unavailableFicheErrorMessage: "Document not available. Please contact your administrator.",
    deleteFileSuccessMessage: "The file was successfully deleted. Your modifications have been saved up and will be effective in 24h.",
    deleteFileErrorMessage: "An error has occurred while deleting document.",
    unvailableGpsCoordinatesForBusinessContractOrJobSiteErrorMessage: "Be careful, incorrect address, please modify the address with the button \"Modify contract/job addresses and contours\"",
    unvailableGpsCoordinatesForOtherStructureTypesErrorMessage: "Be careful, incorrect address",
    invalidFileSizeErrorMessage: "At least one of the selected files is empty or weighs more than 100Mo, please make a new file selection.",
    invalidFileExtensionErrorMessage: "At least one of the selected files does not have an extension, please redo a selection of files all including an extension.",
    requestUserRefreshPage: "Please refresh your screen",
    popUpPolygonInformationMessage: "The measurements shown below are related to the surface area visible on the screen.",
    latitude: "Latitude (deg)",
    longitude: "Longitude (deg)",
    opacity: "Opacity",
    minutes: "minutes",
    kilometers: "km",
    kilometersLabel: "Kilometers",
    Meters: "Meters",
    Feet: "Feet",
    Yard: "Yards",
    Miles: "Miles",
    SqMeters:"Sq. meters",
    SqKilometres:"Sq. Kilometers",
    SqFeet:"Sq. feet",
    Acres: "Acres",
    SqMiles:"Sq. miles",
    kilograms: "kg",
    saveChoice: "Save my choice",
    noData: "No data",
    initialLocation: "Initial location",
    newLocation: "New location",
    preserveLayerDisplay: "Preserve layer display",
    layerName: "Layer name",
    optionalLayerName: "Layer name",
    myLayer: "My layer",
    structures: "Structures",
    height: "Height",
    length: "Length",
    width: "Width",
    tons: "Tons",
    regions: "Regions",
    departments: "Departments",
    cantons: "Cantons",
    municipalities: "Municipalities",

    siren: "SIREN",
    name: "Name",
    firstName: "First name",
    profil: "Profile",
    genericErrorMessage: "An error has occurred",
    create: "Create",
    language: "Language",
    myProfile: "My profile",

    help: "Help",
    tutorials: "Tutorials",
    webSite: "Website",
    guidedTour: "Guided tour",

    poiCorrectionFormTitle: "Correction form",
    poiCorrectionFormSubtitle: "You have identified an anomaly, let us know by completing this form.",
    poiCorrectionFormIdFieldLabel: "Id",
    poiCorrectionFormDescriptionFieldLabel: "Description",
    poiCorrectionFormValidateButton: "Submit",
    poiCorrectionFormSuccessMessage: "Your message has been sent.",
    poiCorrectionFormErrorMessage: "An error has occurred while sending your message.",
    poiEditAddressPerimeterEcontractButton: "Modify contract addresses",
    poiEditAddressPerimeterJobButton: "Modify job addresses and contours",
    poiEditAddressTooltip: "Add or modify address",
    poiEditPerimeterTooltip: "Add or edit contour",

    poiSearchTooltip: "Search for a specific address or point among those already displayed",


    vectuelMapLoading: "Loading...",
    vectuelMapLoadingError: "An error has occured.",
    vectuelMapSearchAddressGroup: "Address",
    vectuelMapSearchPoiGroup: "Points of interest",
    vectuelMapItemsResult: "results",
    vectuelMapSearchLabel: "Search",

    vectuelMapObjectType: "Objects",
    vectuelMapUeTitle: "Agency perimeter",
    vectuelMapLevel2Activities: "Activities",
    vectuelMapDateYear: "Year",
    vectuelMapDocumentDataCategory: "File",
    vectuelMapDocumentExtension: "Format",
    vectuelMapEquipmentType: "Vehicule type",
    vectuelMapErtTitle: "Territory ",
    vectuelMapCircularEconomyGrouping: "CE Label",
    vectuelMapSupplierCompany: "Company",
    vectuelMapSupplierSolvencyScore: "Solvency score",
    vectuelMapClient: "Client",
    vectuelMapJobsiteStatus: "Job status",
    vectuelMapProductId: "Products ID",
    vectuelMapProductLabel: "Products label",
    vectuelMapProductSpecialCode: "Special products",
    vectuelMapEcontractStatus: "Businesses status",
    vectuelMapPhysicalSiteType: "Site type",

    vectuelMapFilterTooltipUeTitle: "Filter the markers Jobs, Projects and Material/equipments by Exploitation units",
    vectuelMapFilterTooltipLevel2Activities: "Filter jobs, exploitation units, production units and Arctique suppliers by activity",
    vectuelMapFilterTooltipDateYear: "Filter jobs and businesses by creation year",
    vectuelMapFilterTooltipDocumentDataCategory: "Filter jobs, businesses and exploitation units by file category associated",
    vectuelMapFilterTooltipDocumentExtension: "Filter jobs, businesses and exploitation units by file format associated",
    vectuelMapFilterTooltipEquipmentType: "Filter materials/equipements by vehicule type", 
    vectuelMapFilterTooltipErtTitle: "Filter exploitation units, production units, jobs, businesses and materials by territory",
    vectuelMapFilterTooltipCircularEconomyGrouping: "Filter production units by circular economy label",
    vectuelMapFilterTooltipSupplierCompany: "Filter suppliers by company name",
    vectuelMapFilterTooltipSupplierSolvencyScore: "Filter suppliers by solvency score Infolegale",
    vectuelMapFilterTooltipClient: "Filter jobs and businesses by client",
    vectuelMapFilterTooltipJobsiteStatus: "Filter jobs by status",
    vectuelMapFilterTooltipProductId: "Filter jobs by ID products",
    vectuelMapFilterTooltipProductLabel: "Filter jobs by products label",
    vectuelMapFilterTooltipProductSpecialCode: "Filter jobs by special products",
    vectuelMapFilterTooltipEcontractStatus: "Filter businesses by status",
    vectuelMapFilterTooltipPhysicalSiteType: "Filter sites by type",

    vectuelMapFilterSubmitButton: "Submit",
    vectuelMapFiltersChooseToDisplay: "Choose filters to display",
    vectuelMapFiltersDisplaySelected: "Display selected filters",
    vectuelMapPOIInfosTab: "Information",
    vectuelMapPOIContactsTab: "Contacts",
    vectuelMapPOIFilesTab: "Files",
    vectuelMapPOIFieldId: "ID",
    vectuelMapPOIFieldLabel: "Name",
    vectuelMapPOIFieldShape: "Surface (km²)",
    vectuelMapPOIFieldLength: "Length (km)",
    vectuelMapPOIFieldTotalPop: "overall population",
    vectuelMapPOIFieldStatus: "Status",
    vectuelMapPOIFieldPop94: "1994 population",
    vectuelMapPOIFieldPop04: "2004 population ",
    vectuelMapPOIFieldHouseholdNumber: "Household number",
    vectuelMapPOIFieldOsmId: "OSM Id",
    vectuelMapPOIFielCode: "Code",
    vectuelMapPOIFielClass: "Class",
    vectuelMapPOIFieloneWay: "One way",
    vectuelMapPOIFielMaxSpeed: "Max speed",
    vectuelMapPOIFielBridge: "bridge",
    vectuelMapPOIFielTunnel: "Tunnel",
    vectuelMapPOIFieldContract: "Project's name",
    vectuelMapPOIFieldErt: "Territory",
    vectuelMapPOIFieldUE: "Exploitation unit",
    vectuelMapPOIFieldSJ: "Legal society",
    vectuelMapPOIFieldAddress: "Address",
    vectuelMapPOIFieldActivities: "Activities",
    vectuelMapPOIFieldInfracareLink: "URL link to InfraCARE",
    vectuelMapPOIFieldClient: "Client",
    vectuelMapPOIFieldJobsiteAmount: "Amount",
    vectuelMapPOIFieldMainContractManager: "Main works manager",
    vectuelMapPOIFieldMainForeman: "Main site manager",
    vectuelMapPOIFieldPlannedStartDate: "Starting date",
    vectuelMapPOIFieldJobsiteStatus: "Status",
    vectuelMapPOIFieldJobsiteProducts: "Products",
    vectuelMapPOIFieldJobsiteShowProductsDetails: "Display products",
    vectuelMapPOIFieldJobsiteLocateProduct: "Locate products",
    vectuelMapPOIFieldJobsiteLocateProductErrorMessage: "No product location plot is available for this job",
    vectuelMapPOIFieldJobsiteProductId: "Product Id",
    vectuelMapPOIFieldJobsiteProductLabel: "Label",
    vectuelMapPOIFieldJobsiteProductSegmentation: "Segmentation",
    vectuelMapPOIFieldJobsiteProductStartDate: "Starting date",
    vectuelMapPOIFieldJobsiteProductEndDate: "End date",
    vectuelMapPOIFieldJobsiteProductQuantity: "Quantity",
    vectuelMapPOIFieldJobsiteProductUnits: "Unit",
    vectuelMapPOIFieldJobsiteERT: "Territory/ERT",
    vectuelMapPOIFieldJobsiteUP: "Industry",
    vectuelMapPOIFieldJobsiteProductRock: "Rock",
    vectuelMapPOIFieldJobsiteProductBitumen: "Bitumen",
    vectuelMapPOIFieldJobsiteProductColor: "Color",
    vectuelMapPOIFieldJobsiteProductGranulometry: "Granulometry",
    vectuelMapPOIFieldJobsiteProductAsphaltFamily: "Asphalt family",
    vectuelMapPOIFieldJobsiteProductClass: "Class",
    vectuelMapPOIFieldJobsiteProductColasSpecial: "Product Colas special",
    vectuelMapPOIFieldJobsiteProductTemperature: "Temperature",
    vectuelMapPOIFieldJobsiteProductTemperatureRange: "Gamme T°",
    vectuelMapPOIFieldJobsiteProductProductAsphaltAggregateRatio: "Ratio AE",
    vectuelMapPOIFieldJobsiteProductOptimized: "Optimized",
    vectuelMapPOIFieldEcontractStatus: "Status",
    vectuelMapPOIFieldUESites: "Sites",
    vectuelMapPOIFieldJobsites: "Job list",
    vectuelMapPOIFieldDocuments: "Linked documents",
    vectuelMapPOIFieldEquipmentType: "Vehicle type",
    vectuelMapPOIFieldEquipmentBrand: "Brand",
    vectuelMapPOIFieldEquipmentModel: "Model",
    vectuelMapPOIFieldEquipmentLicensePlate: "License plate",
    vectuelMapPOIFieldGpsCoordinates: "Coordinates",
    vectuelMapPOIFieldEquipmentStatus: "Current Operational Status",
    vectuelMapPOIFieldLastMaintenanceDate: "Last WO",
    vectuelMapPOIFieldNextMaintenanceDate: "WO's to come",
    vectuelMapPOIFieldSupplierSite: "RS Colas Group",
    vectuelMapPOIFieldCompany: "Company",
    vectuelMapPOIFieldSupplierActivities: "Industry codes",
    vectuelMapPOIFieldSiret: "SIRET (FR)",
    vectuelMapPOIFieldSupplierSolvencyScore: "Solvency score",
    vectuelMapPOIFieldSupplierContacts: "Contacts",
    vectuelMapPOIFieldJobsiteId: "Job ID",
    vectuelMapPOIFieldComments: "Comments",
    vectuelMapPOIFieldAuscultationLastStatementDate: "Last statement date",
    vectuelMapPOIFieldAusculationTotalLength: "Covered road length",
    vectuelMapPOIFieldAusculationBadLength: "% bad status",
    vectuelMapPOIFieldAusculationTomonitorLength: "% to monitor",
    vectuelMapPOIFieldAusculationGoodLength: "% good status",
    vectuelMapPOIOpenCorrectionFormLabel: "Modify information",
    vectuelMapPOIOpenCorrectionFormToolTipNoUe: "Unable to modify contract/job addresses and contours which is not attached to an EU",
    vectuelMapPOIOpenCorrectionFormToolTipNoRight: "You don't have the rights on this contract/job to modify adresses and contours",
    vectuelMapPOIChantier360Label: "Chantier 360",
    vectuelMapPOICarbonCounterLabel: "CCC",
    vectuelMapSelectAllFilters: "Select all",
    vectuelMapOpenDataNationalLabel: "National Open Data",
    vectuelMapOpenDataDepartmentalLabel: "Departmental Open Data",
    vectuelMapCantonaleLabel: "County",
    vectuelMapColasDataLabel: "Colas data",
    vectuelMapPOIFieldCompetitorArea: "Area",
    vectuelMapPOIFieldInsee: "INSEE (FR)",
    vectuelMapPOIEmptyAdress: "Specify the adress by clicking at the bottom of the page",
    vectuelMapPOIEmptyTooltip: "It is by default the UE address",
    vectuelMapPOIFieldPlanonLink: "OCRE PLANON",
    
    vectuelMapMyLayers: "My layers",
    vectuelMapNoLayersSelected: "No layers are selected",
    vectuelMapNoMarkersSelected: "No markers are selected",

    vectuelMapPOIProposalI: "Proposal index",
    vectuelMapPOIProposalT: "Proposal t",
    vectuelMapPOIUrsn: "USRN",
    vectuelMapPOISectionRe: "Section re",
    vectuelMapPOIRoadname: "Road name",
    vectuelMapPOIRoadNumber: "Road number",
    vectuelMapPOIRoadClass: "Road class",
    vectuelMapPOICostEstimationInPounds: "Cost estimation (£)",
    vectuelMapPOILengthMeter: "Length (m)",
    vectuelMapPOISurfaceSquareMeter: "Area (m²)",
    vectuelMapPOIDamageAreaSquareMeter: "Damaged area (m²)",
    vectuelMapPOIGrade1: "Grade 1 (m²)",
    vectuelMapPOIGrade2: "Grade 2 (m²)",
    vectuelMapPOIGrade3: "Grade 3 (m²)",
    vectuelMapPOIGrade4: "Grade 4 (m²)",
    vectuelMapPOIGrade5: "Grade 5 (m²)",
    vectuelMapPOIMajorJCT: "Major jct",
    vectuelMapPOIW3w: "w3w",
    vectuelMapPOIGid: "GID",
    vectuelMapPOISurveyDate: "Survey date",
    vectuelMapPOIGrade: "Grade",
    vectuelMapPOIGradeDesc: "Grade desc",
    vectuelMapPOIDamages: "Damages",
    vectuelMapPOIDamagesWidthMeter: "Damage width (m)",
    vectuelMapPOIDamagesAreaSquareMeter: "Damage area (m²)",
    vectuelMapPOISurface: "Surface",
    vectuelMapPOIWitdhMeter: "Width (m)",
    vectuelMapPOIUts: "UTS",
    vectuelMapPOILinkImage: "Image link",
    vectuelMapPOIResurveyed: "Resurveyed",
    vectuelMapPOIWard: "Ward",
    vectuelMapPOIRoadType: "Road type",
    vectuelMapPOIJunction: "junction",
    vectuelMapPOIMajorJunction: "Major junction",
    vectuelMapPOIMain: "Main",
    vectuelMapPOIRoadOver: "Road over",
    vectuelMapPOIOsName: "OS name",
    vectuelMapPOICorridor: "Corridor",
    vectuelMapPOIWpa: "WPA",
    vectuelMapPOIRadius: "Radius",
    vectuelMapPOIAngle: "Angle",
    vectuelMapPOIAltitude: "Altitude",
    vectuelMapPOIGradient: "Gradient",
    vectuelMapPOIAdjacency: "Adjacency",

    vectuelMapPOIFieldBrgmIndex: "INDEX",
    vectuelMapPOIFieldBrgmAbbreviation: "Designation",
    vectuelMapPOIFieldBrgmInfoTerreLink: "INFOTERRE Link",
    vectuelMapPOIFieldBrgmInfoTerreLinkDeleted: "This borehole is no longer available on Infoterre.",
    vectuelMapPOIFieldBrgmHasGeoCrossSection: "Geological cross section",
    vectuelMapPOIFieldBrgmHasOtherGeoCrossSection: "Other cross section",
    vectuelMapPOIFieldBrgmVerifiedGeoLog: "Verified Geological log",
    vectuelMapPOIFieldBrgmWaterPoint: "Water",
    vectuelMapPOIFieldBrgmXEntered: "X coordinate",
    vectuelMapPOIFieldBrgmYEntered: "Y coordinate",
    vectuelMapPOIFieldBrgmProjectionEntered: "System of projection entered",
    vectuelMapPOIFieldBrgmCoordinateUnitEntered: "Unit of coordinate entered",
    vectuelMapPOIFieldBrgmZSoil: "Z altitude",
    vectuelMapPOIFieldBrgmZSoilPrecision: "Lex Z soil precision",
    vectuelMapPOIFieldBrgmZAltitude: "Z DBalti",
    vectuelMapPOIFieldBrgmType: "Nature of the point",
    vectuelMapPOIFieldBrgmDepthProbe: "Investigation thickness",
    vectuelMapPOIFieldBrgmTubeDiameter: "Casing diameter",
    vectuelMapPOIFieldBrgmGroundWaterDepth: "Water level in the soil",
    vectuelMapPOIFieldBrgmSoilWaterMeasureDate: "Date of water level",
    vectuelMapPOIFieldBrgmZOriginCrossSection: "Z origin of cross section",
    vectuelMapPOIFieldBrgmCrossSectionDate: "Date of the cross section",
    vectuelMapPOIFieldBrgmEndWorkDate: "End of work date",
    vectuelMapPOIFieldBrgmGeoMapNumber: "Geological map number",
    vectuelMapPOIFieldBrgmGeoMapName: "Geological map name",
    vectuelMapPOIFieldBrgmPointCondition: "Point status",
    vectuelMapPOIFieldBrgmExecutionMaterial: "Execution material",
    vectuelMapPOIFieldBrgmExploitationType: "Type of exploitation",
    vectuelMapPOIFieldBrgmRecognitionType: "Type of survey",
    vectuelMapPOIFieldBrgmDocuments: "Associate docs",
    vectuelMapPOIFieldBrgmDocumentsReference: "Reference of docs",
    vectuelMapPOIFieldBrgmFiledDate: "Date of the file",
    vectuelMapPOIFieldBrgmFillDate: "Entry date",
    vectuelMapPOIFieldBrgmLastUpdateDate: "Last update date",

    vectuelMapPOIFieldRefineryTel: "Phone",
    vectuelMapPOIFieldRefineryFax: "Fax",
    vectuelMapPOIFieldRefineryEmail: "Email",
    vectuelMapPOIFieldRefineryCapacity: "Total Capacity (10³T/year)",
    vectuelMapPOIFieldRefineryVaccumDistillation: "Vacuum distillation (10³T/year)",
    vectuelMapPOIFieldRefineryOperator: "Operator",
    vectuelMapPOIFieldRefineryBlowing: "Blowing (10³T/year)",
    vectuelMapPOIFieldRefineryCatalyticCracking: "Catalytic cracking (T/d)",
    vectuelMapPOIFieldRefineryVisbreaking: "Visbreaking (T/d)",
    vectuelMapPOIFieldRefineryDeasphalting: "Deasphalting",
    vectuelMapPOIFieldRefineryOtherUnits: "Other units",
    vectuelMapPOIFieldRefineryProdCapacity: "Production capacity (T/year)",
    vectuelMapPOIFieldRefineryShortResidue: "Short residue",
    vectuelMapPOIFieldRefineryHeavyDistillate: "Heavy distillate",
    vectuelMapPOIFieldRefineryAromaticExtract: "Aromatic extract",
    vectuelMapPOIFieldRefineryVisbrokenResidue: "Visbroken residue",
    vectuelMapPOIFieldRefineryOtherRawMaterials: "Other raw materials",
    vectuelMapPOIFieldRefineryOnLineBlending: "On line blending",
    vectuelMapPOIFieldRefineryAdditive: "Additive",
    vectuelMapPOIFieldRefineryOtherProcess: "Other process",
    vectuelMapPOIFieldRefineryBitumenType: "Bitumen type",
    vectuelMapPOIFieldRefineryUpDate: "Update",

    vectuelMapPOIFieldCircularEconomyLabel: "Circular economy label",
    vectuelMapPOIPropetiesEquipmentCode:"Equipment code",
    vectuelMapPOIPropetiesEquipmentBrand:"Brand equipment",
    vectuelMapPOIPropetiesEquipmentModel:"Equipment model",
    vectuelMapPOIPropetiesQuantity:"Quantity",
    vectuelMapPOIPropetiesEquipmentCodeWorkTime:"Work time",
    vectuelMapPOIPropetiesDistance: "Distance",

    vectuelMapPOILocationTypeLabel: "Site type",

    vectuelMapOpenDataLayerCategoryAdministrative: "Administrative",
    vectuelMapOpenDataLayerCategoryTownPlanning: "Town Planning",
    vectuelMapOpenDataLayerCategoryEnvironment: "Environment",
    vectuelMapOpenDataLayerCategoryEnergyInfrastructure: "Energy infrastructure",
    vectuelMapOpenDataLayerCategoryRoadInfrastructure: "Roads",
    vectuelMapCwacLayerCategory: "CWAC Data",
    vectuelMapDepartmental92LayerCategory: "92 - Hauts de Seine",
    vectuelMapOpenDataLayerCategoryTransportInfrastructure: "Transport Infrastructure",
    vectuelMapOpenDataLayerCategoryUrbanPlanning: "Urbanism",
    vectuelMapMyLayersCategoryItinerary: "Itinerary layers",
    vectuelMapMyLayersCategoryCreatedLayers: "Created layers",
    vectuelMapMyImportedLayersCategory: "Imported layers",
    vectuelOpenData: "Open Data",

    vectuelStreetViewLabel: "Street View",
    vectuelMapLayersLabel: "Layers",
    vectuelMapDisplayLabel: "Display",
    vectuelMapLegendLabel: "Legend",
    vectuelMapToolsLabel: "Tools",
    vectuelMapPlotsLabel: "Plots",
    vectuelMapMarkersLabel: "Markers",
    vectuelMapCalculationLabel: "Calculate",
    vectuelMapDrawingLabel: "Design",
    vectuelMapImportLabel: "Import",
    vectuelMapOthersLabel: "Others",
    vectuelMapDrawingClearLabel: "Clear",
    vectuelMapDrawingLabelFeaturesList: "Features",
    vectuelMapDrawingLabelFeature: "Feature",

    vectuelMapToolDrawingPoint: "Point",
    vectuelMapToolDrawingMultiPoint: "MultiPoint",
    vectuelMapToolDrawingLine: "Line",
    vectuelMapToolDrawingMultiLine: "MultiLine",
    vectuelMapToolDrawingPolygon: "Polygon",
    vectuelMapToolDrawingMultiPolygon: "MultiPolygon",
    vectuelMapToolDrawingText: "Text",
    vectuelMapToolDrawingDrawPoint: "Draw a point",
    vectuelMapToolDrawingDrawLine: "Draw a line",
    vectuelMapToolDrawingDrawPolygon: "Draw a polygon",
    vectuelMapToolDrawingDrawText: "Add a text",
    vectuelMapToolDrawingEnterName: "Enter a name",
    vectuelMapToolDrawingCreate: "Create",
    vectuelMapToolDrawingExport: "Export",
    vectuelMapToolDrawingSave: "Save",
    vectuelMapToolDrawingSaveNew: "Save as new",
    vectuelMapToolDrawingSaveNewTooltip:"Save as a new layer.",
    vectuelMapToolDrawingPropertieName: "Property name",
    vectuelMapToolDrawingPropertieValue: "Value",

    vectuelMapLayerCatchment: "Catchment career",
    vectuelMapLayerJobContour: "Job contours",
    vectuelMapLayerRoadSurvey: "Road survey",
    vectuelMapLayerColasCatchmentUe: "Sectorization of exploitation units",
    vectuelMapLayerColasPKroute:"kilometer points",
    vectuelMapLayerClaySwellingRisk: "FR - Clay swelling risk",
    vectuelMapLayerLandGeologicalRisks: "FR - Geological risk layer",
    vectuelMapLayerLandGeology: "FR - Geological layer",
    vectuelMapLayerLandNaturalRisks: "FR - Natural risks",
    vectuelMapLayerLandRegister: "FR - Land register",
    vectuelMapLayerLandRegisterPrecision: "FR - Land register precision",
    vectuelMapLayerLandSoilPermeability: "FR - Soil permeability",
    vectuelMapLayerPostesHtaBt: "FR - Enedis - Source stations HV/LV",
    vectuelMapLayerPostesSourcesHta: "FR - Enedis - Source stations HV/HV",
    vectuelMapLayerPoteauxHtaBt: "FR - Enedis - Poles HV or LV",
    vectuelMapLayerReseauABt: "FR - Enedis - overhead lines LV",
    vectuelMapLayerReseauAHt: "FR - Enedis - overhead lines HV",
    vectuelMapLayerReseauBt: "FR - Enedis - underground lines LV",
    vectuelMapLayerReseauHta: "FR - Enedis - underground lines HV",
    vectuelMapLayerGazNetwork: "FR - GRDF - gas pipes",
    vectuelMapLayerTrees: "FR - Tree",
    vectuelMapLayerBusStops: "FR - Bus stop",
    vectuelMapLayerPedestrianCrossings: "FR - Pedestrian crossing",
    vectuelMapLayerTrafficSignals: "FR - Traffic signal",
    vectuelMapLayerHospitals: "FR - Hospital",
    vectuelMapLayerRetirementHomes: "FR - Retirement home",
    vectuelMapLayerParkings: "FR - Parking lot",
    vectuelMapLayerWastelands: "FR - Wasteland",
    vectuelMapLayerWasteDisposals: "FR - Waste disposal",
    vectuelMapLayerStores: "FR - Store",
    vectuelMapLayerBikeParkings: "FR - Bike park",
    vectuelMapLayerBikePaths: "FR - Bike path",
    vectuelMapLayerSchools: "FR - School",
    vectuelMapLayerPublicServices: "FR - Public service",
    vectuelMapLayerChargingStations: "FR - Charging station",
    vectuelMapLayerBuildingPermits: "FR - Building permit",
    vectuelMapLayerNoiseExposures: "FR - Noise exposure",
    vectuelMapLayerPlu: "FR - PLU",
    vectuelMapLayerAccidents: "FR - Accident",
    vectuelMapLayerChargingPointsIrve: "Electric charging stations",
    vectuelMapLayerParkingsSup500: "Car parks (Minimum 500m²)",
    vectuelMapLayerParkingZone: "Parking areas",

    vectuelMapLayerCarriageway2022: "EN - Carriageway 2022",
    vectuelMapLayerPatching2022: "EN - Patching 2022",
    vectuelMapLayerCW2022: "EN - CW 2022",
    vectuelMapLayerCW2021: "EN - CW 2021",

    vectuelMapLayerRoadSurveyTooltip: "Infracare is a platform for more effective multi-year management, with the aim of monitoring, maintaining and improving the condition of infrastructures, by promoting the logic of preventive maintenance. From infracare.colas.com",
    vectuelMapLayerLandRegisterTooltip: "Continuous, georeferenced digital cadastral information for land use, urban planning and network management. - From geoservices.ign.fr",
    vectuelMapLayerLandRegisterPrecisionTooltip: "The degree of precision and updating of cadastral data - From geoservices.ign.fr",
    vectuelMapLayerLandNaturalRisksTooltip: "Municipalities concerned by a natural risk prevention plan - From georisques.gouv.fr",
    vectuelMapLayerLandGeologicalRisksTooltip: "Abandoned underground cavities of non-mining origin - From geoservices.brgm.fr",
    vectuelMapLayerLandGeologyTooltip: "1/1,000,000 geological map of mainland France - From infoterre.brgm.fr",
    vectuelMapLayerLandSoilPermeabilityTooltip: "Rate of soil sealing in mainland France and overseas territories - From geoservices.ign.fr",
    vectuelMapLayerClaySwellingRiskTooltip: "Clay shrink-swell exposure zones - From geoservices.brgm.fr",
    vectuelMapLayerPostesHtaBtTooltip: "Position of HTA/BT source and distribution substations on the distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerPostesSourcesHtaTooltip: "Position of HTA/HTA source and distribution substations on the entire distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerPoteauxHtaBtTooltip: "Position of HTA and BT poles and of underground aero lift on the entire distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerReseauABtTooltip: "Position of low-voltage (BT) overhead lines on the entire distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerReseauAHtTooltip: "Position of Medium Voltage (HTA) overhead lines on the entire distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerReseauBtTooltip: "Position of low-voltage (BT) underground lines on the entire distribution network operated by Enedis  - From data.enedis.fr",
    vectuelMapLayerReseauHtaTooltip: "Position of underground Medium Voltage (HTA) lines on the entire distribution network operated by Enedis - From data.enedis.fr",
    vectuelMapLayerGazNetworkTooltip: "All pipes in service in the gas distribution network operated by GRDF.",
    vectuelMapLayerChargingPointsIrveTooltip: "Data on the geographical location and technical characteristics of electric vehicle charging stations and points open to the public - From data.gouv.fr",
    vectuelMapLayerParkingsSup500Tooltip: "Parking areas of over 500m² - From geoservice.ign.fr 2023",
    vectuelMapLayerParkingZoneTooltip: "Land units containing uncovered parking areas of over 500m² (reporting data) - From geoservice.ign.fr 2022",

    vectuelMapLayerRegions: "MA - Region",
    vectuelMapLayerProvinces: "MA - Province",
    vectuelMapLayerMunicipalities: "MA - Municipality",
    vectuelMapLayerCities: "MA - City",
    vectuelMapLayerRails: "MA - Rail",
    vectuelMapLayerRoads: "MA - Road",

    vectuelMapLayerMapStyleOsm2D: "OSM 2D",
    vectuelMapLayerMapStyleSat2D: "SAT 2D",
    vectuelMapLayerMapStyleOsm3D: "OSM 3D",
    vectuelMapLayerMapStyleSat3D: "SAT 3D",
    vectuelMapLayerMapStyleGlobe3D: "GLOBE 3D",

    vectuelMapLayerPopUpCategory: "Category",
    vectuelMapLayerPopUpNature: "Nature",
    vectuelMapLayerPopUpName: "Name",
    vectuelMapLayerPopUpPinName: "Pin name",
    vectuelMapLayerPopUpLineName: "Line name",
    vectuelMapLayerPopUpStatus: "Status",
    vectuelMapLayerPopUpCreationDate: "Created date",
    vectuelMapLayerPopUpUpdateDate: "Update date",
    vectuelMapLayerPopUpCity: "City",
    vectuelMapLayerPopUpAddress: "Address",
    vectuelMapLayerPopUpPollution: "Pollution",
    vectuelMapLayerPopUpType: "Type",
    vectuelMapLayerPopUpTypeFr: "Type FR",
    vectuelMapLayerPopUpOperator: "Operator",
    vectuelMapLayerPopUpWebsite: "Website",
    vectuelMapLayerPopUpPhone: "Phone",
    vectuelMapLayerPopUpCapacity: "Capacity",
    vectuelMapLayerPopUpAccess: "Access",
    vectuelMapLayerPopUpOpeningHour: "Opening Hour",
    vectuelMapLayerPopUpEmail: "Email",
    vectuelMapLayerPopUpPermitId: "Permit ID",
    vectuelMapLayerPopUpLotSize: "Lot size",
    vectuelMapLayerPopUpDAUState: "DAU status",
    vectuelMapLayerPopUpDAUType: "DAU type",
    vectuelMapLayerPopUpBuilderName: "Builder name",
    vectuelMapLayerPopUpOperatorName: "Operator name",
    vectuelMapLayerPopUpNbChargingPoint: "Number charging point",
    vectuelMapLayerPopUpZone: "Zone",
    vectuelMapLayerPopUpStoppingDate: "Date",
    vectuelMapLayerPopUpDocRef: "Link",
    vectuelMapLayerPopUpId: "Id",
    vectuelMapLayerPopUpLabel: "Name",
    vectuelMapLayerPopUpZoneType: "Zone type",
    vectuelMapLayerPopUpKill: "Kill",
    vectuelMapLayerPopUpInjured: "Injured",
    vectuelMapLayerPopUpHospitalized: "Hospitalized",
    vectuelMapLayerPopUpLightInjured: "Light injured",
    vectuelMapLayerPopUpDate: "Date",
    vectuelMapLayerPopUpDepartmentName: "Department name",
    vectuelMapLayerPopUpRegionName: "Region name",
    vectuelMapLayerPopUpNaturePostesHtaBt: "Public distribution station (HV/LV station)",
    vectuelMapLayerPopUpNaturePostesSourcesHta: "Source station and HV/HV Dispatch item",
    vectuelMapLayerPopUpNaturePoteauxHtaBt: "HV or LV pole",
    vectuelMapLayerPopUpNatureReseauABt: "Low Voltage airline (LV)",
    vectuelMapLayerPopUpNatureReseauAHt: "Medium Voltage airline (HV)",
    vectuelMapLayerPopUpNatureReseauBt: "Low Voltage underground Line (LV)",
    vectuelMapLayerPopUpNatureReseauHta: "Medium Voltage underground line (HV)",
    vectuelMapLayerPopUpTitlePostesHtaBt: "HV/LV station",
    vectuelMapLayerPopUpTitlePostesSourcesHta: "Source station and HV/HV Dispatch item",
    vectuelMapLayerPopUpTitlePoteauxHtaBt: "HV or LV pole",
    vectuelMapLayerPopUpTitleReseauABt: "LV airline",
    vectuelMapLayerPopUpTitleReseauAHt: "HV airline",
    vectuelMapLayerPopUpTitleReseauBt: "LV underground line",
    vectuelMapLayerPopUpTitleReseauHta: "HV underground line",
    vectuelMapLayerPopUpTabTitleProperties: "Properties",
    vectuelMapLayerPopUpTabTitleInformations: "Informations",
    vectuelMapLayerPopUpTabTitleStyle: "Style",
    vectuelMapLayerPopUpSizeField: "Marker size",
    vectuelMapLayerPopUpWeightField: "Width",
    vectuelMapLayerPopUpSizeFieldSmall: "Small",
    vectuelMapLayerPopUpSizeFieldNormal: "Normal",
    vectuelMapLayerPopUpSizeFieldBig: "Big",
    vectuelMapLayerPopUpIconField: "Marker",
    vectuelMapLayerPopUpDeleteButton: "Delete feature",
    vectuelMapLayerPopUpFontSizeField: "Text size",
    vectuelMapLayerPopUpBorderColor: "Stroke",
    vectuelMapLayerPopUpStroke:"Stroke",
    vectuelMapLayerPopUpAddproperty: "Add property",


    vectuelMapObjectTypeFilterCategoryBoreholes: "Boreholes",
    vectuelMapObjectTypeFilterCategoryColas: "Colas",
    vectuelMapObjectTypeFilterCategoryRefineries: "Refineries",
    vectuelMapObjectTypeFilterCategorySuppliers: "Suppliers",

    vectuelMapObjectTypeFilterPluralArctiqueData: "Competing suppliers",
    vectuelMapObjectTypeFilterPluralBrgmBoreholes: "BRGM Boreholes",
    vectuelMapObjectTypeFilterPluralLegalCompany: "Legal Companies",
    vectuelMapObjectTypeFilterPluralExploitationUnit: "Exploitation Units",
    vectuelMapObjectTypeFilterPluralJob: "Jobs",
    vectuelMapObjectTypeFilterPluralMaterialEquipment: "Material/Equipments",
    vectuelMapObjectTypeFilterPluralModel3d: "3D Models",
    vectuelMapObjectTypeFilterPluralProductionUnit: "Production Units",
    vectuelMapObjectTypeFilterPluralProject: "Projects",
    vectuelMapObjectTypeFilterPluralRefinery: "Refineries",
    vectuelMapObjectTypeFilterPluralRoadStatement: "Road Statements",
    vectuelMapObjectTypeFilterPluralSupplier: "Various suppliers",
    vectuelMapObjectTypeFilterPluralPhysicalSite: "Physical Sites",

    vectuelMapObjectTypeFilterPluralArctiqueDataTooltip: "COLAS suppliers and related activities - Source: Arctique",
    vectuelMapObjectTypeFilterPluralBrgmBoreholesTooltip: "Subsoil Bank data and links to InfoTerre fact sheets - Source: infoterre.brgm.fr",
    vectuelMapObjectTypeFilterPluralLegalCompanyTooltip: "Legal Companies - Source: Hubble",
    vectuelMapObjectTypeFilterPluralExploitationUnitTooltip: "COLAS Exploitation Units - Source: Hubble",
    vectuelMapObjectTypeFilterPluralJobTooltip: "Jobs completed or in progress - Source: Hubble",
    vectuelMapObjectTypeFilterPluralMaterialEquipmentTooltip: "Material/Equipments - Source: MyEquipment",
    vectuelMapObjectTypeFilterPluralModel3dTooltip: "3D models added on the map",
    vectuelMapObjectTypeFilterPluralProductionUnitTooltip: "Production Units - Quarries and industries - Source: Hubble",
    vectuelMapObjectTypeFilterPluralProjectTooltip: "Pojects - Source: HUBBLE",
    vectuelMapObjectTypeFilterPluralRefineryTooltip: "COLAS Refineries - Source: Hubble",
    vectuelMapObjectTypeFilterPluralRoadStatementTooltip: "Infracare is a platform for more effective multi-year management, with the aim of monitoring, maintaining and improving the condition of infrastructures, by promoting the logic of preventive maintenance - From infracare.colas.com",
    vectuelMapObjectTypeFilterPluralSupplierTooltip: "COLAS suppliers and related activities - Source: Arctique",
    vectuelMapObjectTypeFilterPluralPhysicalSiteTooltip: "Physical Sites - Source: HUBBLE",

    ficheGridColumnFileExtension: "Ext.",
    ficheGridColumnFicheTitle: "File name",
    ficheGridColumnFicheCategory: "Category",
    ficheGridColumnFicheStatus: "Phase",
    ficheGridColumnFicheCreatedAt: "Created",
    ficheGridDownloadLabel: "Download",
    ficheGridZoomLabel: "Zoom",
    poiFileListUploadButton: "Add file",
    poiFileListUploadButtontooltipRights: "You don't have the rights to add a document on this object",
    poiFileListUploadButtontooltipMissingUe: "Unable to add a file because the POI is not attached to any EU",
    poiFileListNoDownloadPermission: "You need authorization to download this file",
    ToastAddFileSuccess: "Your documents will be accessible via the map within 24 hours",
    ToastUpdateAddressAndContourSuccess: "Your modification will be visible via the map within 24 hours",
    ToastGenericTechnicalError: "An error occurred  - Please contact you administrator",


    itineraryTitle: "Itinerary",
    itineraryTitleTooltip:"Route between two or more points - Distance, travel time, carbon weight",
    itineraryResetForm: "Reset form",
    itineraryTrip: "Trip",
    itineraryOneWay: "One way",
    itineraryReturn: "Return",
    itineraryAddDestination: "Add destination",
    itineraryCalculationLabel: "Calculate the itinerary",
    distanceCalculationLabel: "Calculate the distance",
    carbonCalculationLabel: "Calculate the carbon",
    itineraryFastestLabel: "The fastest",
    itineraryShortestLabel: "The shortest",
    itineraryDragToReorderLabel: "Drag to reorder",
    itineraryAddressPlaceHolderLabel: "Choose destination or click on the map",
    itineraryCloseLabel: "close",
    itineraryInvalidAddressesErrorMessage: "Incorrect addresses, please add at least two valid addresses to calculate the itinerary",
    itineraryGeneralErrorMessage: "Itinerary calculation not possible",
    itineraryTruckWarningMessage: "Route calculations are carried out while always avoiding prohibited lanes and roads according to the vehicle profiles applied.",
    calculateDistanceErrorMessage: "Distance calculation not possible",
    calculateCarbonErrorMessage: "Carbon calculation not possible",
    maxCarbonErrorMessage: "The maximum carbon value has been reached. Enter a value less than",
    geoLocationNotAvailable: "Geolocation not available",
    itineraryVehicleLabel: "Vehicle",
    itineraryCarLabel: "Car",
    itineraryTruckLabel: "Truck",
    itineraryVehicleTypeLabel: "Vehicle type",
    itineraryFuelTypeLabel: "Fuel type",
    itineraryTruckDefaultFuelTypeLabel: "Diesel (off-road diesel) only",
    itineraryTransportedTonsLabel: "Number of transported tons",
    itineraryTotalWeight: "Total Weight",
    itineraryHazardousMaterials: "Hazardous Materials",
    itineraryVehicleParameters: "Vehicle Parameters",
    itineraryTruckProfileSaveSuccess: "Profile saved",
    itineraryCreateTruckProfile: "Create new profile",

    itineraryTruckWeightValue1: "Below or equal to 7,5T",
    itineraryTruckWeightValue2: "Between 7,5T and 12T",
    itineraryTruckWeightValue3: "Between 12T and 19T",
    itineraryTruckWeightValue4: "Between 19T and 26T",
    itineraryTruckWeightValue5: "Between 26T and 32T",
    itineraryTruckWeightValue6: "Above 32T",

    itineraryTruckHazardousMaterialNone: "None",
    itineraryTruckHazardousMaterialGeneral: "Diverses",
    itineraryTruckHazardousMaterialExplosive: "Explosives",
    itineraryTruckHazardousMaterialHarmfulToWater: "Harmful to water",

    isochronousTitle: "Isochronous",
    IsodistanceTitle: "Isodistance",
    IsocarbonTitle: "Isocarbon",
    weatherFormTilte: "Weather",
    isochronousTitleTooltip:"Curve linking all points located at the same time interval from an address",
    IsodistanceTitleTooltip: "Curve linking all points located at the same distance from an address",
    IsocarbonTitleTooltip: "Curve linking all points located at an equivalent amount of carbon emissions from the same address",

    weatherFormAddress:"Address",
    weatherFormPeriod:"Period",
    weatherFormUnit:"Unit system",
    weatherFormPeriodToday:"Today",
    weatherFormPeriodYesterday:"Yesterday",
    weatherFormPeriodTomorrow:"Tomorrow",
    weatherFormPeriodLastSevenDays:"Last 7 days",
    weatherFormPeriodLastMonth:"Last 30 days",
    weatherFormPeriodNextSevenDays:"Next 7 days",
    weatherFormPeriodPlaceholder:"Select a period",
    weatherFormUnitPlaceholder:"Select an unit system",
    weatherFormUnitMetric:"Metric (°C, km)",
    weatherFormUnitUk:"UK (°C, miles)",
    weatherFormUnitUS:"US (°F, miles)",
    weatherfromButton:"Show results",

    weatherTableDate:"Date",
    weatherTableAverage:"Average temperature",
    weatherTableTempMax:"Maximum temperature",
    weatherTableTempMin:"Minimum temperature",
    weatherTableFeltAvg:"Average felt temperature",
    weatherTableFeltMax:"Maximum felt temperature",
    weatherTableFeltMin:"Minimum felt temperature",
    weatherTableHumidity:"Humidity (%)",
    weatherTablePrecipitation:"Precipitation",
    weatherTablePrecipProbability:"Precipitation - Probability (%)",
    weatherTablePrecipType:"Precipitation type",
    weatherTableSnow:"Snow (%)",
    weatherTableSnowDepth:"Snow - Depth",
    weatherTableWindSpeed:"Wind speed ",
    weatherTablePressure:"Pressure (hPa)",
    weatherTableCloudCover:"Cloudcover (%)",
    weatherTableSolarRadition:"Solar radiation (W/m2)",
    weatherTableSolarEnergy:"Solar energy (MJ/m2)",
    weatherTableUvIndex:"UV index",
    weatherTableSunrise:"Sunrise",
    weatherTableSunset:"Sunset",
    weatherTableWeatherCondition:"Weather conditions",

    weatherTabDaily:"Daily",
    weatherTabHourly:"Hourly",
    weatherTabStations:"Stations",

    weatherTableStationsName:"Name",
    weatherTableStationsId:"Identifier",
    weatherTableStationsDistance:"Distance",
    weatherTableStationsLat:"Latitude",
    weatherTableStationsLng:"Longitude",

    inexZoomToDisplay: "Zoom to display more details",
    inexLayerLabel: "Plots",
    inexParcelLabel: "Plot",
    inexSiteLabel: "Site",
    inexTabDescription: "Description",
    inexTabLandRegistry: "Land registry",
    inexTabUrbanism: "Urbanism",
    inexTabEnvironment: "Environment",
    inexTabInfrastructures: "Infrastructures",
    inexTabContact: "Contact",
    inexSeeDetailButton: "Detailed data",
    inexSeeDetailButtonAlertMessage: "INEX data with paid query",
    inexDetailFieldIdentifier: "ID",
    inexDetailFieldSurface: "Surface",
    inexDetailFieldCommune: "Town",
    inexDetailFieldUpdateDate: "Update",
    inexDetailFieldNaceCode: "NACE code",
    inexDetailSubtitleBuildings: "Buildings",
    inexDetailSubtitleBuilding: "Building",
    inexDetailBuildingTotalSurface: "Total surface",
    inexDetailBuildingExploitableSurface: "Exploitable surface",
    inexDetailBuildingHeight: "Height",
    inexDetailBuildingType: "Type",
    inexDetailBuildingDeltaRoofHeight: "Delta roof (height max - min)",

    inexDetailSubtitleLandRegistrySituation: "Land registry situation",
    inexDetailSubtitleLandCover: "Ground cover",
    inexDetailSubtitleLastParcelSales: "Last sales on the plot (since 2017)",
    inexDetailSubtitleMeanPriceParcelSection: "Average price per m² (Cadastral Section)",
    inexDetailSubtitleOwners: "Owners",
    inexDetailFieldParcelLandMeanPriceCityWithBuilding: "Mean price on city (built land)",
    inexDetailFieldParcelLandMeanPriceCityWithoutBuilding: "Mean price on city (not built land)",
    inexDetailFieldParcelLandMeanPriceSectionWithBuilding: "Mean price on section (built land)",
    inexDetailFieldParcelLandMeanPriceSectionWithoutBuilding: "Mean price on section (not built land)",

    inexDetailLastParcelSalesPrice: "Price",
    inexDetailLastParcelSalesSurface: "Surface",
    inexDetailLastParcelSalesYear: "Year",
    inexDetailLastParcelSalesAddress: "Address",
    inexDetailFieldParcelOwnerGroup: "Group",
    inexDetailSubtitleInfrastructureTransport: "Transport infrastructure",
    inexDetailFieldNationalRoad: "National Road",
    inexDetailFieldDepartmentalRoad: "Departmental Road",
    inexDetailFieldHighway: "Highway",
    inexDetailFieldRailwayJunction: "Railway junction",

    inexDetailDocumentationLabel: "Documentation",
    inexDetailSubtitleParcelEnvGeneral: "General",
    inexDetailFieldParcelEnvFirstHouse: "1st house",
    inexDetailSubtitleParcelEnvAgriculturalArea: "Agricultural area",
    inexDetailFieldParcelEnvCultureType: "Culture type",
    inexDetailFieldParcelEnvCompensatoryMeasures: "Compensatory measures",
    inexDetailSubtitleParcelEnvNaturalZones10km: "Natural areas within 10 kms",
    inexDetailTooltipZnieff: "ZNIEFF 1: areas of great biological or ecological interest; ZNIEFF 2: large, rich and little-modified natural complexes",
    znieffLabel: "ZNIEFF",
    inexDetailFieldParcelEnvNaturalPark: "Regional Natural Park",
    inexDetailFieldParcelEnvNationalPark: "National Park",
    inexDetailFieldParcelEnvNaturalReserve: "Nature reserve",
    inexNatura2000Label: "Natura 2000",
    inexApbLabel: "APB (biotope orders)",
    inexDetailFieldParcelEnvEcoCorridor: "Ecological corridor (SRADDET)",
    inexDetailSubtitleParcelUrbanismMainDocuments: "Main documents",
    inexDetailSubtitleParcelUrbanismDocuments: "Urban planning documents",
    inexDetailSubtitleParcelUrbanismOtherDocuments: "Other documents",
    inexDetailSubtitleParcelUrbanismBuildingPermit: "Building permit",
    inexDetailFieldParcelUrbanismZoning: "Zoning",
    inexScotLabel: "SCOT",
    inexScotTooltip: "Schéma de COhérence Territoriale",

    inexDetailParcelBuildingPermitDepositDate: "Deposit date",
    inexDetailParcelBuildingPermitDeliveryDate: "Delivery date",
    inexDetailParcelBuildingPermitOperationType: "Operation type",
    inexDetailParcelBuildingPermitSurfaceDemolition: "Demolition surface",
    inexDetailParcelBuildingPermitSurfaceConstruction: "Construction surface",
    inexDetailParcelBuildingPermitProjectOwnerLabel: "Project owner",
    inexDetailParcelBuildingPermitProjectOwnerSiret: "Legal identifier",
    inexDetailParcelBuildingPermitProjectOwnerName: "Name",
    inexDetailParcelBuildingPermitProjectOwnerPhone: "Phone",
    inexDetailParcelBuildingPermitStartingDate: "Starting date",
    inexDetailParcelBuildingPermitBuildingType: "Building type",
    inexDetailParcelBuildingPermitProjectOwnerContact: "Contact",
    inexDetailParcelBuildingPermitSurfaceRenovation: "Renovation surface",


    inexSiteFamilyExutoire: "Outlet",
    inexSiteFamilyFriche: "Special sites",
    inexSiteFamilyFournisseur: "Other competitors",
    inexSiteFamilyConcurrent: "Competitors",

    inexSiteSubfamilyTitleCentresTri: "Waste Sorting Center",
    inexSiteSubfamilyTooltipCentresTri: 'Waste sorting centers. Zoom in to view data',
    inexSiteSubfamilyTitleDechetteriesPubliques: "Recycling Center",
    inexSiteSubfamilyTooltipDechetteriesPubliques: "Public drop-off centers. Zoom in to view data - Source: SINOE",
    inexSiteSubfamilyTitleAnciensSitesIndustriels: "Former Industrial Sites and Service Activities",
    inexSiteSubfamilyTooltipAnciensSitesIndustriels: "Database of former industrial sites and service activities. Zoom in to view data - Source: BASIAS",
    inexSiteSubfamilyTitleSitesEtSolsPollues: "Polluted Sites ans Soils",
    inexSiteSubfamilyTooltipSitesEtSolsPollues: "Administrative information on suspected or confirmed pollution. Zoom in to view data - Source: BASOL",
    inexSiteSubfamilyTitleSitesICPE: "ICPE sites",
    inexSiteSubfamilyTooltipSitesICPE: "Sites of classified environmental protection facilities. Zoom in to view data - Source: Géorisques",
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_FFB: "Construction Waste treatment centers",
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_FFB: "Collection points throughout France, designed to facilitate the recovery and recycling of construction waste. Zoom in to view data - Source: dechets-chantier.ffbatiment.fr",
    inexSiteSubfamilyTitleFriches: "Brownfield",
    inexSiteSubfamilyTooltipFriches: "Data on brownfield sites (industrial, commercial, housing...). Zoom in to view data -  Source: CARTOFRICHES",
    inexSiteSubfamilyTitleISDI_ISDND: "ISDI & ISDND",
    inexSiteSubfamilyTooltipISDI_ISDND: "Inert waste storage facility and Non-hazardous waste storage facility. Zoom in to view data - Source: georisques.gouv.fr",
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_MATERRIO: "Construction Waste treatment centers",
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_MATERRIO: "Collection points throughout France, designed to facilitate the recovery and recycling of construction waste. Zoom in to view data - Source: materrio.construction",

    inexLayerLabelReseauFerroviaire: "Railway Network",
    inexLayerLabelGares: "Freight Station",
    inexLayerLabelZfe: "Low Emission Zone",
    inexLayerLabelPpa: "Atmosphere Protection Plan",
    inexLayerLabelPort: "Freight Port",
    inexLayerLabelZoneEtSecteurCc: "Zoning",
    inexLayerLabelServitudeUtilitePubliquePPR: "Risk and prevention plan",
    inexLayerLabelParcelleAgricole: "Agricultural plots",
    inexLayerLabelZnieff1: "Natural Zone Type1",
    inexLayerLabelZnieff2: "Natural Zone Type2",
    inexLayerLabelNatura2000Habitat: 'Habitats NATURA 2000 Zone',
    inexLayerLabelNatura2000Oiseaux: 'NATURA 2000 Zone',
    inexLayerLabelParcNational: "National Park",
    inexLayerLabelParcNaturelRegional: "Regional Natural Park",
    inexLayerLabelReserveNaturelleNationale: "Natural Reserves",
    inexLayerLabelCorridorEcologique: "Ecological Corridor",
    inexLayerLabelZonesHumides: "Wet Areas",
    inexLayerLabelMesureCompensatoire: "Compensatory Measures",
    inexLayerLabelBssExport: "Soil Bank",
    inexLayerLabelTronconHydrographique: "Watercourse",
    inexLayerLabelRteLigneAerienne: "RTE Aerial lines",
    inexLayerLabelReserveNationaleChasseFauneSauvage: "National wildlife hunting reserve",
    inexLayerLabelApb: "Biotope orders",
    inexLayerLabelZppa: "Areas of presumption of archaelogical prescription",
    inexLayerLabelTrameCarriereMine: "Mine and quarry frame",
    inexLayerLabelZonesPatrimonialesProtegees: "Protected Heritage Areas",
    inexLayerLabelPatrimoineRemarquable: "Remarkable heritage sites",
    inexLayerLabelMonumentHistorique: "Historic monument surroundings",
    inexLayerLabelServitudeUtilitePubliqueAC2: "Listed and classified sites",

    inexLayerRteLigneAerienneTooltip: "All overhead lines of the public electricity transmission network managed by RTE - From odre.opendatasoft.com",
    inexLayerReseauFerroviaireTooltip: "All lines on the national rail network - From data.sncf.com",
    inexLayerZfeTooltip: "National database of Low Emission Zones set up by French cities - From transport.data.gouv.fr",
    inexLayerPpaTooltip: "The PPA is a planning tool designed to restore and preserve air quality in the region. ",
    inexLayerPortTooltip: "Port infrastructure to facilitate trade, freight flows and connectivity between different regions of the world. ",
    inexLayerGaresTooltip: "List of freight stations on the National Rail Network - From ressources.data.sncf.com",
    inexLayerZoneEtSecteurCcTooltip: "Zoning in French urban planning documents and communal map sectors - From geoportail-urbanisme.gouv.fr",
    inexLayerServitudeUtilitePubliquePPRTooltip: "Natural and technological risk prevention plans. ",
    inexLayerZppaTooltip: "Zones in which development projects affecting the subsoil are presumed to be subject to archaeological prescriptions prior to their implementation. ",
    inexLayerTrameCarriereMineTooltip: "Digital mining cadastre - From camino.beta.gouv.fr",
    inexLayerParcelleAgricoleTooltip: "All crop islets and parcels of farmers registering for the Common Agricultural Policy - From geoservices.ign.fr",
    inexLayerZnieff1Tooltip: "Type 1 Natural Areas of Ecological, Faunistic and Floristic Interest  - From geoservices.ign.fr",
    inexLayerZnieff2Tooltip: "Type 2 Natural Areas of Ecological, Faunistic and Floristic Interest - From geoservices.ign.fr",
    inexLayerReserveNationaleChasseFauneSauvageTooltip: "Natural areas protected to achieve specific natural heritage conservation objectives - From geoservices.ign.fr",
    inexLayerApbTooltip: "Administrative acts taken to preserve the habitats of protected species, the biological balance or the functionality of environments. ",
    inexLayerNatura2000HabitatTooltip: "Special Areas of Conservation (ZSC) for the conservation of habitat types and animal and plant species - From geoservices.ign.fr",
    inexLayerNatura2000OiseauxTooltip: "Special Protection Areas (ZPS), for the conservation of wild bird species or as breeding, moulting, wintering or staging areas for migratory birds - From geoservices.ign.fr",
    inexLayerParcNationalTooltip: "Protected areas subject to specific regulations (articles L331 and R331 of the Environment Code) to safeguard their natural and cultural heritage, recognized as exceptional - From geoservices.ign.fr",
    inexLayerParcNaturelRegionalTooltip: "Regional nature parks (PNR) contribute to environmental protection, regional planning, economic and social development, and public education and training - From geoservices.ign.fr",
    inexLayerReserveNaturelleNationaleTooltip: "Areas protecting terrestrial or maritime sectors where the conservation of fauna, flora, soil, water, mineral and fossil deposits or, more generally, the natural environment, is of particular importance - From geoservices.ign.fr ",
    inexLayerCorridorEcologiqueTooltip: "Passages linking natural areas. ",
    inexLayerZonesHumidesTooltip: "Land, whether farmed or not, that is usually permanently or temporarily flooded or waterlogged with fresh, salt or brackish water. ",
    inexLayerMesureCompensatoireTooltip: "Areas covered by environmental and land-use planning regulations - From catalog.cdata.cerema.fr",
    inexLayerBssExportTooltip: "All data on underground structures (drillings, boreholes, wells and springs) in France. - From geoservices.brgm.fr",
    inexLayerTronconHydrographiqueTooltip: "All rivers in mainland France. ",
    inexLayerZonesPatrimonialesProtegeesTooltip: "Protected Heritage Areas. ",
    inexLayerRegionsTooltip: "All regions of France - From data.gouv.fr",
    inexLayerDepartementsTooltip: "All departments in France - From data.gouv.fr",
    inexLayerCantonsTooltip: "All electoral cantons only (no pseudo-cantons or fictitious cantons) in mainland France and French overseas departments and territories. - From public.opendatasoft.com",
    inexLayerCommunesTooltip: "All communes in France, but also districts - From data.gouv.fr",
    inexLayerParcellesCadastralesTooltip: "All cadastral parcels in France - From api.gouv.fr",
    inexLayerPatrimoineRemarquableTooltip: "Remarkable heritage sites.",
    inexLayerMonumentHistoriqueTooltip: "Historic monument surroundings.",
    inexLayerServitudeUtilitePubliqueAC2Tooltip: "Listed and classified sites.",

    othersWeatherLabel: "Weather",

    echoStandardQuestion: "Environmental Standard",
    echoWaterStress: "Water stress",
    echoWaterDischarges: "Water Discharges",
    echoWasteManagement: "Waste Management",
    echoBiodiversity: "Biodiversity",
    echoLocalDialogue: "Local Dialogue",
    echoPagintationcampagne: "Campaign",

    echoStandardQuestionTooltip: { 
        intro: [
            "% YES to environmental checklist questions identified as « Environmental Standards » to be given priority at sites."
        ], 
        content: []
    },
    echoWaterStressTooltip: { 
        intro: [
            "If the site is located in an area of extremely high water stress, an action plan is put in place to limit external water consumption:"
        ], 
        content: [
            "If 100%: YES",
            "If 0%: NO",
            "If « Blank » : Not applicable",
        ]
    },
    echoWaterDischargesTooltip: { 
        intro: [
            "% YES to questions on controlling water discharges to limit pollution. Some questions only apply to certain activities.",
            "For areas of the site at risk of water and soil pollution (handling/storage of hazardous liquid products):",
        ], 
        content: [
            "The area is sealed",
            "Presence of a functional and efficient hydrocarbon separator",
            "Annual maintenance of the hydrocarbon separator",
            "For sea and river loading and unloading facilities, a marine arm must be fitted to prevent the use of flexible hoses and enable automatic passive shutdown of upstream and downstream pipes in the event of breakage or disassembly.",
            "Hazardous liquid products cannot be discharged into stormwater or wastewater systems or into the natural environment, even accidentally.",
            "Check that the products discharged (particularly from sinks) are suitable for treatment (even off-site) and/or with the acceptance criteria of the wastewater network.",
            "Inside the workshop, there are no drainage points to the rainwater network.",
            "Presence of a system to isolate the site from the outside environment (rainwater system, watercourse, neighboring property, etc.) in the event of an accidental spill or fire.",
            "Annual check on the operation of the device used to isolate the site from the outside environment in the event of an accidental spill or fire.",
            "Washing water from the production hall and equipment rinses are treated in a manner appropriate to their composition and the acceptance criteria of rainwater and/or wastewater networks.",
            "Water used for washing materials undergoes appropriate treatment before discharge or reuse. Solid residues from the treatment system are treated or recycled.",
            "Water used for washing and rinsing operations (mixers, rotors, etc.) undergoes appropriate treatment before discharge or reuse. Solid residues from the treatment system are treated or recycled.",
            "Annual analyses of water discharged from the hydrocarbon separator, the treatment system and dewatering water.",
            "All water discharge analyses comply with local regulations (by-laws, discharge permits, etc.).",
            "Existence of and compliance with a maintenance plan for individual wastewater treatment systems (septic tanks, etc.)",
        ]
    },
    echoWasteManagementTooltip: { 
        intro: [
            "% YES to questions on waste management based on the following 5 elements:"
        ], 
        content: [
            "Waste tracking register to check compliance with treatment or disposal procedures",
            "Waste tracking register enabling traceability to the waste's final destination (disposal or recovery).",
            "Cleanliness of waste collection area",
            "Labeling waste sorting containers",
            "Proper waste sorting: hazardous waste separated from non-hazardous waste",
        ]
    },
    echoBiodiversityTooltip: { 
        intro: [
            "% YES to questions on the implementation of action(s) in favor of biodiversity based on the following 3 elements:"
        ], 
        content: [
            "Implementation of action(s) to promote biodiversity on the site",
            "Support for action(s) by an expert (ecologist, local association, national association, etc.)",
            "Pedagogical communication on internal and/or external action(s)",
        ]
    },
    echoLocalDialogueTooltip: { 
        intro: [
            "% YES to questions on setting up a local dialogue structure based on the following 4 elements:"
        ], 
        content: [
            "Existence of a communication meeting less than a year old with local residents (individuals, farmers, industrialists, etc.).",
            "Existence of a communication meeting less than a year old with local authorities (specialized and municipal administrations)",
            "A system for recording external complaints or requests is in place and is systematically used.",
            "Existence of formalsite responses to external complaints or requests",
        ]
    },

    chooseCountry: "Select a country : ",
    Maroc: "Morocco",
    France: "France",
    England: "England",

    importLayer: "Import a layer",
    importLayerNameLabel: "Layer Name :",
    importLayerCancelButton: "Cancel",
    importLayerUploadButton: "Upload",
    importLayerFileSizeExeededError: "Maximum file size 20 Mo",
    importLayerNormesNotValidError: "Projection system supported : WGS84 EPSG 4326",
    importLayerFileContentError: "Error reading file, please check its contents",
    importLayerSaveError: "An error occurred while saving the imported layer",
    importLayerTooltip: "Import geojson layers of less than 20 Mo - Projection: EPSG 4326 WGS 84",
    importLayerFileError: "Error: file could not be imported",
    importLayerFileCoordinatesRangeError: "Check the coordinates range values",

    deleteLayerDialogTitle:"Delete Layer",
    deleteLayerDialogContent:"Do you really want to delete this Layer?",
    deleteLayerErrorMessage: "An error has occurred while deleting Layer.",
    editLayerDialogTitle:"Edit Layer Name",
    editLayerDialogContent:"Type the new Layer name",
    editLayerDialogLayerName:"Layer Name",
    editLayerErrorMessage: "An error has occurred while editing Layer name.",

    inexButtonAccess: "Access",

    parisGamesMapLabel: "Paris 2024",
    parisGamesMarkersRoadsLabel: "Points and roads of the Games",
    parisGamesLayerLabel: "Game venues",
    parisGamesLayerTooltip: "Paris's games sites, click on the points to get the information",
    parisGamesSiteNameLabel: "Name: ",
    parisGamesSiteAddressLabel: "Address: ",
    parisGamesSiteCategoryLabel: "Category: ",
    parisGamesSiteCityLabel: "City: ",
    parisGamesSiteClassicSportsLabel: "Classic Sports: ",
    parisGamesSiteParaSportsLabel: "Para Sports:",
    parisGamesRoadsLabel: "Routes and roads affected",
    parisGamesRoadsTooltip: "These lanes are reserved for the transport of accredited persons, as well as for emergency and security vehicles, cabs, ambulances and public transport. The other lanes are open to all other vehicles. Games itineraries are recommended routes for accredited vehicles, but do not constitute reserved lanes.",
    parisGamesRoadsDatesLabel: "Dates: ",
    parisGamesRoadsZoneLabel: "Zones: ",
    
    parisGamesForecastLabel: "Forecast the Games",
    parisGamesDisclaimer: "Les données affichées sont celles communiquées par la Préfecture de Police de Paris et par le Ministère de l'intérieur. Des impacts supplémentaires peuvent être à prévoir localement.",

    
    parisGamesRoadsForecastSubTitle: "Cars: restricted access areas",
    parisGamesRoadsForecastDatePickerLabel: "See restricted access areas as of:",
    parisGamesRoadsForecastHideButtonLabel: "Hide",
    parisGamesRoadsForecastShowButtonLabel: "Show",
    parisGamesRoadsForecastText: "The zones displayed on the screen correspond to areas where access is regulated and subject to an exemption. Access is only regulated between 6:00AM and midnight",
    parisGamesRoadsForecastAskPassJeux: "Request a Pass Jeux access exemption",

    parisGamesTransportsForecastSubTitle: "Public transport: traffic forecast",
    parisGamesTransportsForecastDatePickerLabel: "See the traffic weather for the date and time of:",
    parisGamesTransportsForecastHideButtonLabel: "Hide",
    parisGamesTransportsForecastShowButtonLabel: "Show",
    parisGamesTransportsForecastText: [
        "Public transport lines most affected in Île-de-France:",
        "- Metros: 5, 6, 7, 8, 9, 10, 12, 13 and 14 ;",
        "- Streetcars: T3a and T3b",
        "- RER B, C and D",
        "- Transilien: line J (towards Ermont Eaubonne), line L (towards Versailles Rive Droite), line N, line P (towards Meaux) and line U",
        "Metro stations closed:",
        "- Metro 1:",
        "\"Champs-Elysées - Clémenceau\" - from 01/07 to 21/09",
        "\"Concorde\" and \"Tuileries\" - from 06/17 to 09/21",
        "- Métro 8: \"Concorde\" - from 17/06 to 21/09",
        "- Métro 12: \"Concorde\" - from 17/05 to 21/09",
        "- Métro 13: \"Champs-Elysées - Clémenceau\" - from 01/07 to 21/09",
        "Streetcar stations not served :",
        "- Streetcar T2: \"Porte d'Issy\" and \"Porte de Versailles\" - from 07/25 to 08/11 and 08/29 to 09/07",
        "- Streetcar T3a: \"Porte de Versailles\" - from 07/25 to 08/11 and 08/29 to 07/09",
        "- Streetcar T3b: \"Colette Besson\" - from 27/07 to 10/08 and from 29/08 to 08/09"
    ]    ,
    parisGamesTransportsStationStatusClosed:"Closed station",
    parisGamesTransportsStationStatusGreen:"Preferred station",
    parisGamesTransportsStationStatusYellow:"Busy station",
    parisGamesTransportsStationStatusOrange:"Extremely busy station",
    parisGamesTransportsStationStatusRed:"Station to avoid",
    parisGamesTransportsStationStatusNoImpact:"Station not impacted by the Games",
};